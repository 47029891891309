.teal-borderLeft {
  border-left: 4px solid rgba(0, 128, 128, 0.2);
  height: "650px";
}

.displayFlex-badgeIcon {
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
