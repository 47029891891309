.dashboard-grid-wrapper {
  width: 892px;
  min-height: 130vh;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 5px;
}

.tabs {
  width: 892px;
  margin-left: auto;
  margin-right: auto;
  border-bottom-left-radius: 0px;
}

.dropdown-content-arrow {
  display: none;
}

.wait-return-container {
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 1px;
}

.display-button-flex {
  display: flex;
  align-items: center;
  gap: 30px;
}

.transport-type-filter .MuiSelect-select {
  width: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  min-width: 0 !important;
}
