.ride-alert-list {
  position: fixed;
  z-index: 100;
  top: 65px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
    drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
}

.ride-alert-item {
  animation: fadeIn 300ms;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  background-color: #fecaca;
  color: #450a0a;
  padding: 4px 24px;
  border-bottom: 1px solid #450a0a;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ride-alert-item-text {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 0 auto;
}

.ride-alert-item-text > div {
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 8px;
}

.ride-alert-button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.ride-alert-item-button {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  font-size: 0.9rem;
}

.ride-alert-item-button > span {
  margin: 0 4px 0 8px;
}

.ride-alert-exclaim-icon {
  width: 18px;
  height: 18px;
  fill: currentColor;
}

.ride-alert-arrow-icon {
  width: 12px;
  height: 12px;
  fill: currentColor;
}

.ride-alert-close-button {
  position: absolute;
  right: 24px;
  display: flex;
  padding: 4px 12px;
  color: #450a0a;
}

.ride-alert-close-button > svg {
  width: 18px;
  height: 18px;
}
