* {
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif !important;
  font-weight: 300;
  -ms-overflow-style: none;
  font-feature-settings: "lnum" 1;
}

::-webkit-scrollbar {
  display: none;
}
html {
  height: 100%;
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}
.tabs li.is-active a {
  border-bottom-color: #485fc7;
  color: black;
  font-weight: 500;
}
.tabs {
  overflow-x: hidden;
}
.input,
.select select,
.textarea {
  color: black;
  font-weight: 600;
  font-size: 13.5px;
  border: none;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  box-shadow: none !important;
}

li a {
  background-color: rgba(85, 83, 83, 0.4);
}

.tabs li a {
  color: white;
}

.dropdown-item {
  color: black;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border: none;
}

/* time picker z index */

.css-j5h6pi-MuiPopper-root {
  z-index: 99999 !important;
}

.css-1mtsuo7 {
  z-index: 9999 !important;
}
.tabs ul {
  border-bottom: none;
}

.gm-control {
  font-size: 10px !important;
  padding: 10px;
}


body {
  height: 100%;
}
.App {
  height: 100%;
  min-height: 100%;
}

#root {
  height: 100%;
  min-height: 100%;
}
