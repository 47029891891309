.link {
    display: flex;
    justify-content: center;
    width: 100%;
}

.icons {
    padding: 0 !important;
    font-size: 14px !important;
}

.invisibleText {
    color: transparent;
    user-select: none;
}
