* {
  font-family: "Inter";
  font-weight: 550;
}

.auth-form-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b8c6db;
  background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%);
}

.auto-form-container {
  height: 50%;
  width: 100%;
  min-width: 350px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin-bottom: 150px;
}

.auth-logo {
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 2px;
  text-align: center;
}
.auth-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 300;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.auto-form-text-link-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}

.forgot-password {
  font-size: 15px;
  color: #215261;
}

.have-account {
  font-size: 15px;
  color: #215261;
}

.have-account span {
  color: #215261;
}

a:-webkit-any-link {
  text-decoration: none;
}

a:-webkit-any-link:hover {
  text-decoration: underline;
  color: #215261;
}

.link {
  color: #215261;
}
