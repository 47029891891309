.color {
  width: 100%;
  height: 10px;
}

.teal {
  background: #92c9d6;
}

.darkPurple {
  background: #2f193b;
}

.purple {
  background: #572e72;
}

.lightPurple {
  background: #8260a2;
}
.black {
  background: #000000;
}

.white {
  background: #ffffff;
}
