.details-border {
  margin-top: 20px;
  border-bottom: 1px solid lightgrey;
  padding-bottom: 10px;
}

.details-badge-display-wrap {
  display: flex;
  justify-content: start;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
}
